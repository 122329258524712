<template>
    <div class="wrap">
        <div class="mg-auto" style="width: 1250px;">
            <div class="con_top">
                <h2 class="mb-20"><span>■</span> 급여요율 등록</h2>
    
                <div class="con">
                    <div class="con_table">
                        <table width="900" border="0" cellspacing="0" cellpadding="0" class="col_table mb-00">
                            <tbody>
                                <tr>
                                    <th>등록일 ~ 종료일 <span class="red">*</span></th>
                                    <td class="left">
                                        <crm-datepicker v-model="sdate" :parentDate="sdate" format="yyyy-MM-dd" :language="$store.state.ko" />
                                         ~ 
                                        <crm-datepicker v-model="edate" :parentDate="edate" class="mr-10" format="yyyy-MM-dd" :language="$store.state.ko" />
                                    </td>
    
                                    <th>급여요율<span class="red">*</span></th>
                                    <td class="left">
                                        <input type="text" v-model.trim="pay"  class="ml-20 w-50px" maxlength="3"/>
                                        %
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div class="btns3 mgB80">
                            <a class="btn_sms mr-10 pointer" @click="cancel()">취소</a>
                            <a class="btn_cancle mr-10 pointer" @click="submit()">등록</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </template>
    
    <script>
    import {
        GetUrlParams
    } from '@/utils/common.js'
    export default {
        data: () => ({
            idxCrmStaff: -1, //crm_staff idx
            sdate: '', //시작일
            edate: '', //종료일
            pay: 0, //급여요율
            btnDisabled:false, //등록버튼 활성화/비활성화 여부
        }),
    
        mounted () {
            var oParams = GetUrlParams()
            this.idxCrmStaff = oParams.idxCrmStaff

            if( !this.idxCrmStaff ) {
                alert('오류가 발생했습니다. 관리자에게 문의해주세요')   
                this.cancel()
            }
        },
        methods: {
            // 등록
            submit() {

                if ( !this.sdate ) {
                    alert("등록일을 입력해주세요")
                    return
                }
                if ( !this.edate ) {
                    alert("종료일을 입력해주세요")
                    return
                }
                if ( !this.pay ) {
                    alert("급여요율을 입력해주세요")
                    return
                }
    
                var vo = {
                    idxCrmStaff : this.idxCrmStaff,
                    sdate : this.sdate,
                    edate: this.edate,
                    pay: this.pay,
                }
                if(this.btnDisabled){
                    alert('저장중입니다. 잠시만 기다려주세요.');
                    return false;
                }
    
                this.btnDisabled = true;
                this.axios.post('/api/v1/userPay', vo)
                    .then(res => {
                        if (res.data.err === 0) {
                            alert(res.data.result)
                            this.cancel()
                        } else {
                            alert(res.data.result)
                        }
                    })
                    .catch(err => {
                        console.log(err)
                    })
                    .finally(()=>{
                        this.btnDisabled = false;
                    })
            },
    
            // 이전 페이지로 이동
            cancel() {
                this.$router.push({ name: 'origin-counselorManageCard', query: { idx: this.idxCrmStaff } })
            },

        }
    }
    </script>